import { RichTextProps } from '@graphcms/rich-text-react-renderer/dist/types';
import { Audio } from './Audio';
import { Iframe } from './Iframe';
import { Image } from './Image';
import { Link } from './Link';
import { Video } from './Video';

export const elements: Required<RichTextProps['renderers']> = {
  class: ({ children, className }) => <div className={className}>{children}</div>,
  video: Video,
  iframe: Iframe,
  blockquote: ({ children }) => <blockquote>{children}</blockquote>,
  ul: ({ children }) => <ul>{children}</ul>,
  ol: ({ children }) => <ol>{children}</ol>,
  li: ({ children }) => <li>{children}</li>,
  p: ({ children }) => <p>{children}</p>,
  h1: ({ children }) => <h1>{children}</h1>,
  h2: ({ children }) => <h2>{children}</h2>,
  h3: ({ children }) => <h3>{children}</h3>,
  h4: ({ children }) => <h4>{children}</h4>,
  h5: ({ children }) => <h5>{children}</h5>,
  h6: ({ children }) => <h6>{children}</h6>,
  table: ({ children }) => <table>{children}</table>,
  table_head: ({ children }) => <thead>{children}</thead>,
  table_body: ({ children }) => <tbody>{children}</tbody>,
  table_row: ({ children }) => <tr>{children}</tr>,
  table_cell: ({ children }) => <td>{children}</td>,
  table_header_cell: ({ children }) => <th>{children}</th>,
  bold: ({ children }) => <b>{children}</b>,
  italic: ({ children }) => <i>{children}</i>,
  underline: ({ children }) => <u>{children}</u>,
  code: ({ children }) => <code>{children}</code>,
  code_block: ({ children }) => (
    <pre
      style={{
        whiteSpace: 'pre',
        wordWrap: 'break-word',
        overflowX: 'auto',
        WebkitOverflowScrolling: 'touch',
        fontFamily: 'monospace',
      }}
    >
      {children}
    </pre>
  ),
  Asset: {
    audio: (props) => <Audio {...props} url={props.url} />,
    image: (props) => <Image {...props} src={props.url} />,
    video: (props) => <Video {...props} src={props.url} />,
  },
  list_item_child: ({ children }) => <>{children}</>,
  embed: {},
  link: {},
  a: Link,
  img: Image,
};
