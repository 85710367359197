import { ReactNode } from 'react';
import {
  CheckboxInput,
  CheckboxLabel,
  CheckboxLabelHtml,
  Container,
  ErrorMessage,
  StyledLabel,
} from '../../../styles';
import type { ICheckbox } from '../../types';
import { getLabel } from './helper';

export const Checkbox = ({
  field,
  formikProps,
  count,
}: {
  field: ICheckbox;
  formikProps: any;
  count: number;
}): ReactNode => (
  <Container className={field?.hidden ? 'hidden' : ''}>
    <StyledLabel htmlFor={field.fieldName}>
      <CheckboxInput
        type="checkbox"
        id={field.fieldName}
        name={field.fieldName}
        onChange={formikProps.handleChange}
        value={formikProps.values[field.fieldName]}
        data-test={`checkbox-${field.fieldName}`}
      />
      <CheckboxLabel>
        {field.label ? getLabel(field) : null}
        {field.longText?.html && (
          <CheckboxLabelHtml
            dangerouslySetInnerHTML={{
              __html: `${field.longText?.html} ${
                !field.label && field.required ? '<span>*</span>' : ''
              }`,
            }}
          />
        )}
      </CheckboxLabel>
    </StyledLabel>
    {formikProps.errors[field.fieldName] ||
    (field.required && !formikProps.values[field.fieldName] && count > 1) ? (
      <ErrorMessage data-test={`checkbox-${field.fieldName}-error`}>
        {field.requiredErrorMessage}
      </ErrorMessage>
    ) : (
      ''
    )}
  </Container>
);
