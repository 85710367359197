import { ChangeEvent } from 'react';
import { CheckboxInput, ErrorMessage, MultiStepOptionLabel } from '../../../../styles';
import { SharedFieldProps } from '../Mapper';

export type MultiCheckboxField = {
  __typename: 'MultiStepPromotionFormCheckboxes';
  title: string;
  checkboxes: {
    label: string;
    value: string;
  }[];
  requiredErrorMessage: string;
};

type MultiCheckboxProps = MultiCheckboxField & SharedFieldProps;

export const MultiCheckbox = ({
  title,
  checkboxes,
  requiredErrorMessage,
  stepValues,
  onFieldValueChange,
  showErrorMessage,
}: MultiCheckboxProps) => {
  const currentValue = stepValues.find((stepValue) => stepValue.title === title)?.value;
  const activeCheckboxes = currentValue?.split('+') ?? [];

  // build string from activeCheckboxes
  // e.g. ['value1', 'value2'] => 'value1+value2'
  const getUpdatedValue = (value: string, isChecked: boolean) => {
    // if checkbox is checked and there are no activeCheckboxes, return checkbox value
    if (isChecked && activeCheckboxes.length === 0) {
      return value;
    }

    // add checkbox value to activeCheckboxes and join them with '+'
    if (isChecked) {
      return [...activeCheckboxes, value].join('+');
    }

    // remove checkbox value from activeCheckboxes and join them with '+'
    return activeCheckboxes.filter((activeCheckbox) => activeCheckbox !== value).join('+');
  };

  const handleCheckboxClick = (value: string, isChecked: boolean) => {
    const updatedValue = getUpdatedValue(value, isChecked);

    onFieldValueChange({
      value: updatedValue,
      title,
      isValid: Boolean(updatedValue),
    });
  };

  return (
    <>
      {checkboxes.map(({ label, value }) => {
        const isActiveValue = Boolean(
          activeCheckboxes.find((activeCheckbox) => activeCheckbox === value),
        );
        return (
          <MultiStepOptionLabel key={label} $isActive={isActiveValue} htmlFor={label}>
            <CheckboxInput
              type="checkbox"
              id={label}
              value={value}
              name={`${title}-${value}`}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleCheckboxClick(value, event.target.checked)
              }
              checked={isActiveValue}
            />
            <span>{label}</span>
          </MultiStepOptionLabel>
        );
      })}
      {showErrorMessage && <ErrorMessage>{requiredErrorMessage}</ErrorMessage>}
    </>
  );
};
