import type {
  ICheckbox,
  IEmailInput,
  INumberInput,
  IPasswordInput,
  IPasswordInputWithConfirmation,
  ISelect,
  ITextInput,
  ITextareaInput,
} from '../../types';

export const getLabel = (
  field:
    | IPasswordInput
    | IEmailInput
    | IPasswordInputWithConfirmation
    | ITextInput
    | ITextareaInput
    | ISelect
    | ICheckbox
    | INumberInput,
) => (field.required ? `${field.label} *` : field.label);

export const getTodaysDate = () => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0');
  const yyyy = today.getFullYear();
  return `${yyyy}-${mm}-${dd}`;
};
