import { ReactNode } from 'react';
import {
  CheckboxCollectionDiv,
  CheckboxInput,
  Container,
  ErrorMessage,
  Label,
  StyledCollectionCheckbox,
} from '../../../styles';
import type { ICheckbox, ICheckboxCollection } from '../../types';

export const FormList = ({
  field,
  formikProps,
  count,
}: {
  field: ICheckboxCollection;
  formikProps: any;
  count: number;
}): ReactNode => {
  return (
    <Container className={field?.isHidden ? 'hidden' : ''}>
      <Label htmlFor={field.fieldName} id={field.fieldName}>
        {field.title}
      </Label>
      <CheckboxCollectionDiv role="group" aria-labelledby={field.fieldName}>
        {field?.formCheckboxes?.map((checkbox: ICheckbox, index: number) => (
          <>
            <StyledCollectionCheckbox
              htmlFor={checkbox.fieldName}
              // eslint-disable-next-line react/no-array-index-key
              key={`collection-checkbox-${checkbox?.fieldName}-${index}`}
            >
              <CheckboxInput
                type="checkbox"
                id={checkbox.fieldName}
                name={field.fieldName}
                onChange={formikProps.handleChange}
                value={checkbox.fieldName}
                checked={formikProps.values[field.fieldName]?.includes(checkbox.fieldName)}
                data-test={`checkbox-${checkbox.fieldName}`}
              />
              <span>{checkbox.label}</span>
            </StyledCollectionCheckbox>
            {formikProps.errors[field.fieldName] ||
            (checkbox.required && !formikProps.values[checkbox.fieldName]?.length && count > 1) ? (
              <ErrorMessage data-test={`checkbox-${checkbox.fieldName}-error`}>
                {field.requiredErrorMessage}
              </ErrorMessage>
            ) : (
              ''
            )}
          </>
        ))}
      </CheckboxCollectionDiv>
    </Container>
  );
};
