import * as yup from 'yup';
import type { FormFieldAttributes, IDateInput, SalesForceValidationRules } from './types';

export const fieldValidations = {
  FormEmailInput: (field: FormFieldAttributes) =>
    field.required
      ? yup.string().email().required(field.requiredErrorMessage)
      : yup.string().email(),
  FormPasswordInput: (field: FormFieldAttributes) =>
    field.required
      ? yup.string().required(field.requiredErrorMessage).min(8, 'Too short')
      : yup.string().min(8, 'Too short'),
  FormPasswordInputWithConfirmation: yup
    .string()
    .oneOf([yup.ref('password'), undefined], 'Passwords must match')
    .required('Required'),
  FormTextInput: (field: FormFieldAttributes) => {
    let validation = RegExp('');
    switch (field.salesForceValidationRules as SalesForceValidationRules) {
      case 'firstName':
      case 'lastName':
        validation = /^[A-ZÀ-ÿА-я\-'\s]+$/i;
        break;
      case 'city':
        validation = /^[A-ZÀ-ÿА-я.\s]+$/i;
        break;
      case 'streetNumber':
        validation = /^[A-ZÀ-ÿА-я\d\-,.\s]+$/i;
        break;
      case 'zipCode':
        validation = /^[\d-]+$/i;
        break;
    }
    return field.required
      ? yup.string().matches(validation).required()
      : yup.string().matches(validation);
  },
  FormDateInput: (field: IDateInput) =>
    field.required
      ? yup
          .date()
          .max(new Date(), 'invalid')
          .required(field.requiredErrorMessage || 'Required')
          .test('age', field.invalidErrorMessage || 'You are not old enough', function (birthday) {
            if (field.salesForceValidationRules === 'age16') {
              const cutoff = new Date();
              cutoff.setFullYear(cutoff.getFullYear() - 16);
              return birthday <= cutoff;
            }
            return true;
          })
      : yup.string(),
  FormTextareaInput: (field: FormFieldAttributes) =>
    field.required ? yup.string().required(field.requiredErrorMessage) : yup.string(),
  FormCheckbox: (field: FormFieldAttributes) =>
    field.required
      ? yup.boolean().required(field.requiredErrorMessage).oneOf([true])
      : yup.boolean(),
  FormRadioButtonCollection: (field: FormFieldAttributes) =>
    field.required ? yup.string().required(field.requiredErrorMessage) : yup.string(),
  FormFileUploader: (field: FormFieldAttributes) =>
    field.required ? yup.string().required(field.requiredErrorMessage) : yup.string(),
};

export const validationSchemaMap = (fields: any) => {
  const schema: any = {};

  fields?.forEach((field: FormFieldAttributes) => {
    if (field.__typename === 'FormPasswordInputWithConfirmation') {
      schema.password = fieldValidations.FormPasswordInput(field);
      schema.passwordConfirmation = fieldValidations.FormPasswordInputWithConfirmation;
      // @ts-ignore
    } else if (fieldValidations[field.__typename]) {
      // @ts-ignore
      schema[field.fieldName] = fieldValidations[field.__typename](field);
    }
  });

  return schema;
};
