import { ReactNode } from 'react';
import {
  CheckboxCollectionDiv,
  CheckboxInput,
  Container,
  ErrorMessage,
  Label,
  StyledCollectionCheckbox,
} from '../../../styles';
import type { ICheckbox, ICheckboxCollection } from '../../types';

export const CheckboxCollection = ({
  field,
  formikProps,
}: {
  field: ICheckboxCollection;
  formikProps: any;
}): ReactNode => {
  return (
    <Container className={field?.isHidden ? 'hidden' : ''}>
      <Label htmlFor={field.fieldName} id={field.fieldName}>
        {field.title}
        {field.required && ' *'}
      </Label>
      <CheckboxCollectionDiv role="group" aria-labelledby={field.fieldName}>
        {field?.formCheckboxes?.map((checkbox: ICheckbox, index: number) => (
          <StyledCollectionCheckbox
            htmlFor={checkbox.fieldName}
            // eslint-disable-next-line react/no-array-index-key
            key={`collection-checkbox-${checkbox?.fieldName}-${index}`}
          >
            <CheckboxInput
              type="checkbox"
              id={checkbox.fieldName}
              name={field.fieldName}
              onChange={formikProps.handleChange}
              value={checkbox.fieldName}
              data-test={`checkbox-${checkbox.fieldName}`}
            />
            <span>{checkbox.label}</span>
          </StyledCollectionCheckbox>
        ))}
      </CheckboxCollectionDiv>
      {formikProps.errors[field.fieldName] && (
        <ErrorMessage data-test={`checkbox-${field.fieldName}-error`}>
          {formikProps.errors[field.fieldName]}
        </ErrorMessage>
      )}
    </Container>
  );
};
