import { ErrorMessage, MultiStepOptionLabel, RadioInput } from '../../../../styles';
import { SharedFieldProps } from '../Mapper';

export type RadioField = {
  __typename: 'MultiStepPromotionFormRadio';
  title: string;
  radios: {
    label: string;
    value: string;
  }[];
  requiredErrorMessage: string;
};

type RadioProps = RadioField & SharedFieldProps;
export const Radios = ({
  title,
  radios,
  requiredErrorMessage,
  stepValues,
  onFieldValueChange,
  showErrorMessage,
}: RadioProps) => {
  const currentValue = stepValues.find((stepValue) => stepValue.title === title)?.value;
  return (
    <>
      {radios.map(({ label, value }) => {
        const isActiveValue = currentValue === value;
        return (
          <MultiStepOptionLabel key={label} $isActive={isActiveValue} htmlFor={label}>
            <RadioInput
              type="radio"
              id={label}
              value={value}
              name={title}
              onChange={() =>
                onFieldValueChange({
                  value,
                  title,
                  isValid: true,
                })
              }
              className="mr-2"
              checked={isActiveValue}
              autoFocus={isActiveValue}
            />
            <span>{label}</span>
          </MultiStepOptionLabel>
        );
      })}
      {showErrorMessage && <ErrorMessage>{requiredErrorMessage}</ErrorMessage>}
    </>
  );
};
