import axios from 'axios';
import { SingleCheckbox } from 'components/Forms/Common/components/Fields/SingleCheckbox';
import { format } from 'date-fns';
import { forwardRef, useMemo } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { Dictionary } from 'service/dictionary/dictionaryService';
import { BasicSelectInput } from '../Common/components/Fields/BasicSelectInput';
import { DateInput } from '../Common/components/Fields/DateInput';
import { EmailInput } from '../Common/components/Fields/EmailInput';
import { MultiCheckbox } from '../Common/components/Fields/MultiCheckbox';
import { TextInput } from '../Common/components/Fields/TextInput';
import { FriendlyCaptcha } from '../Common/components/FriendlyCaptcha';
import {
  COUNTRIES,
  GENDER_VALUES,
  PROMOTION_SOURCE_TOOL,
  PROMOTION_SOURCE_TYPE,
  SALUTATION_VALUES,
} from './config';
import { getBrand } from './helper/getBrand';
import { getCountryCode } from './helper/getCountryCode';
import { getLegalSubscription } from './helper/getLegalSubscription';
import { Option, getOptions } from './helper/getOptions';

type PromotionAsset = {
  question: string;
  answer: string;
};

type Error = {
  name: string;
  message: string;
  path: string;
};

export type PromotionFormProps = {
  countryCode: string;
  brand: string;
  internationalPromotionId: string;
  externalToolId?: string;
  campaignType?: string;
  promotionType?: string;
  startDate: string;
  endDate: string;
  language: string;
  newsletterSubscriptions: {
    subscriptions: Option[];
  };
  showSalutationField: boolean;
  showBirthdateField: boolean;
  showMobileNumberField: boolean;
  showHomeNumberField: boolean;
  showAddressField: boolean;
  showGenderField: boolean;
  showLegalCheckbox: boolean;
  legalText?: {
    html: string;
  };
  legalCheckboxRequired: boolean;
  translations: Dictionary;
  salutationTranslations: Dictionary;
  genderTranslations: Dictionary;
  promotionAssets: PromotionAsset[];
  onSubmitSuccess: () => void;
  onSubmitError: (errors: Error[]) => void;
};

export const PromotionForm = forwardRef<HTMLFormElement, PromotionFormProps>(
  (
    {
      countryCode,
      brand,
      internationalPromotionId,
      promotionAssets,
      translations,
      salutationTranslations,
      genderTranslations,
      startDate,
      endDate,
      newsletterSubscriptions,
      showSalutationField,
      showBirthdateField,
      showMobileNumberField,
      showHomeNumberField,
      showAddressField,
      showGenderField,
      showLegalCheckbox,
      legalText,
      legalCheckboxRequired,
      campaignType,
      promotionType,
      externalToolId,
      language,
      onSubmitSuccess,
      onSubmitError,
    }: PromotionFormProps,
    ref,
  ) => {
    const {
      register,
      setValue,
      handleSubmit,
      formState: { isValid, errors },
    } = useForm();

    const salutationOptions = getOptions(SALUTATION_VALUES, salutationTranslations);
    const genderOptions = getOptions(GENDER_VALUES, genderTranslations);
    const countryOptions = useMemo(() => {
      return COUNTRIES.map((country) => ({
        value: country.country,
        label: country.country,
      }));
    }, []);

    const handlePromotionFormSubmit = async (data: FieldValues) => {
      if (!isValid) {
        return null;
      }

      try {
        const legalSubscription = getLegalSubscription({
          legalCheckboxChecked: data.legalCheckbox,
          legalCheckboxRequired,
          legalCheckboxVisible: showLegalCheckbox,
        });
        const promotionData = {
          brand: getBrand(brand),
          countryCode,
          internationalPromotionId,
          startDate: format(new Date(startDate), 'yyyy-MM-dd'),
          endDate: format(new Date(endDate), 'yyyy-MM-dd'),
          language,
          website: window?.location?.href,
          submissionTimestamp: new Date().toISOString(),
          promotionSourceTool: PROMOTION_SOURCE_TOOL,
          promotionSourceType: PROMOTION_SOURCE_TYPE,
          questions: promotionAssets,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          subscriptions:
            `${data.subscriptions}${legalSubscription ? `+${legalSubscription}` : ''}`.split('+'),
          legalText: legalText?.html,
          ...(showSalutationField && { salutation: data.salutation }),
          ...(showBirthdateField && { birthdate: data.birthdate }),
          ...(showGenderField && { gender: data.gender }),
          ...(showMobileNumberField && { mobile: data.mobileNumber }),
          ...(showHomeNumberField && { phone: data.homeNumber }),
          ...(showAddressField && {
            address: {
              street: data.street,
              postalCode: data.postalCode,
              city: data.city,
              country: data.country,
              countryCode: getCountryCode(data.country),
            },
          }),
          ...(promotionType && { promotionType }),
          ...(campaignType && { campaignType }),
          ...(externalToolId && { externalToolId }),
          solution: data.solution,
        };

        const response = await axios.post('/next-api/engagement/promotion', promotionData);

        if (response.status === 200) {
          onSubmitSuccess();
        }
      } catch (e: any) {
        const error = e.response.data as Error[];
        onSubmitError(error);
      }
    };

    return (
      <form onSubmit={handleSubmit(handlePromotionFormSubmit)} ref={ref}>
        <div className="flex flex-wrap">
          {showGenderField && (
            <div className="w-full mmd:w-[200px] mmd:mr-[24px]">
              <BasicSelectInput
                options={genderOptions}
                translations={translations}
                fieldName="gender"
                required
                register={register}
                errors={errors}
              />
            </div>
          )}
          {showSalutationField && (
            <div className="w-full mmd:w-[200px]">
              <BasicSelectInput
                options={salutationOptions}
                translations={translations}
                fieldName="salutation"
                required
                register={register}
                errors={errors}
              />
            </div>
          )}
        </div>
        <TextInput
          translations={translations}
          fieldName="firstName"
          required
          register={register}
          errors={errors}
        />
        <TextInput
          translations={translations}
          fieldName="lastName"
          required
          register={register}
          errors={errors}
        />
        <EmailInput
          translations={translations}
          fieldName="email"
          required
          register={register}
          errors={errors}
        />
        {showBirthdateField && (
          <div className="w-full mmd:w-[200px]">
            <DateInput
              translations={translations}
              fieldName="birthdate"
              required
              register={register}
              errors={errors}
            />
          </div>
        )}
        {showMobileNumberField && (
          <TextInput
            translations={translations}
            fieldName="mobileNumber"
            register={register}
            errors={errors}
          />
        )}
        {showHomeNumberField && (
          <TextInput
            translations={translations}
            fieldName="homeNumber"
            register={register}
            errors={errors}
          />
        )}
        {showAddressField && (
          <>
            <div className="w-full mmd:w-[200px]">
              <BasicSelectInput
                translations={translations}
                options={countryOptions}
                required
                fieldName="country"
                register={register}
                errors={errors}
              />
            </div>
            <div className="flex flex-wrap">
              <div className="flex-grow mmd:mr-[24px]">
                <TextInput
                  translations={translations}
                  required
                  fieldName="city"
                  register={register}
                  errors={errors}
                />
              </div>
              <div className="w-full mmd:w-[200px]">
                <TextInput
                  translations={translations}
                  required
                  fieldName="postalCode"
                  register={register}
                  errors={errors}
                />
              </div>
            </div>

            <TextInput
              translations={translations}
              required
              fieldName="street"
              register={register}
              errors={errors}
            />
          </>
        )}
        <MultiCheckbox
          checkboxes={newsletterSubscriptions?.subscriptions ?? []}
          translations={translations}
          fieldName="subscriptions"
          required
          register={register}
          setFieldValue={setValue}
          errors={errors}
        />
        {showLegalCheckbox && (
          <SingleCheckbox
            html={legalText?.html}
            fieldName="legalCheckbox"
            translations={translations}
            required={legalCheckboxRequired}
            register={register}
            errors={errors}
          />
        )}
        <FriendlyCaptcha
          onSuccess={(solution) => {
            setValue('solution', solution);
          }}
        />
      </form>
    );
  },
);
