interface DictionaryItem {
  __typename?: 'DictionaryItem';
  key: string;
  value: string;
  defaultValue?: string;
}

interface Dictionary {
  __typename?: 'Dictionary';
  dictionaryItems: DictionaryItem[];
}

export type { Dictionary, DictionaryItem };

const mapDictionaryToRecords = (dictionary?: Dictionary | null) =>
  (dictionary?.dictionaryItems || []).reduce(
    (labels: Record<string, string>, current: DictionaryItem) => ({
      ...labels,
      [current.key]: current.value,
    }),
    {},
  );

/**
 * retrieves the copy of the key provided from a dictionary object
 * @param key dictionary item key
 * @param dict the dictionary object
 * @returns copy
 */
const getCopy = (key: string, dict?: DictionaryItem[]): string =>
  dict?.filter((item: any) => item.key === key)[0]?.value || '';

export const DictionaryService = {
  getCopy,
  mapDictionaryToRecords,
};
