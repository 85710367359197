import dynamic from 'next/dynamic';
import { ReactNode, useEffect, useState } from 'react';
import { AVAILABLE_THEMES, AVAILABLE_THEMES_TYPE, COLORS } from 'styles/constants';
import { LiquidButton } from '../../../../LiquidButton';
import { ButtonFlexContainer, Container, StyledSubmitButton } from '../../../styles';
import type { ISubmitButton } from '../../types';
const FriendlyCaptcha = dynamic(
  () => import('../../../Common/components/FriendlyCaptcha').then((mod) => mod.FriendlyCaptcha),
  {
    ssr: false,
  },
);

export const SubmitButton = ({
  field,
  formikProps,
  onCaptchaSuccess,
  themeName,
}: {
  field: ISubmitButton;
  formikProps: any;
  onCaptchaSuccess?: (solution: string) => void;
  themeName: AVAILABLE_THEMES_TYPE | null;
}): ReactNode => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <>
      {!!onCaptchaSuccess && isClient && (
        <>
          <FriendlyCaptcha
            onSuccess={(solution) => {
              onCaptchaSuccess(solution);
            }}
          />
        </>
      )}
      <Container>
        <ButtonFlexContainer>
          <StyledSubmitButton type="submit">
            <LiquidButton
              text={field.buttonText}
              variant={themeName === AVAILABLE_THEMES.suprema ? 'primary' : 'none'}
              className={themeName === AVAILABLE_THEMES.suprema ? 'mt-8' : ''}
              disabled={formikProps.isSubmitting}
              data-test={`submit-${field.buttonText}`}
              width={240}
              height={52}
              color={COLORS.primary}
              textColor={COLORS.white}
            />
          </StyledSubmitButton>
        </ButtonFlexContainer>
      </Container>
    </>
  );
};
