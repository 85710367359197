import { LEGAL_SUBSCRIPTION_VALUE } from '../config';

type GetLegalSubscriptionProps = {
  legalCheckboxRequired: boolean;
  legalCheckboxVisible: boolean;
  legalCheckboxChecked: boolean;
};

export const getLegalSubscription = ({
  legalCheckboxRequired,
  legalCheckboxChecked,
  legalCheckboxVisible,
}: GetLegalSubscriptionProps) => {
  if (legalCheckboxChecked || (legalCheckboxRequired && !legalCheckboxVisible)) {
    return LEGAL_SUBSCRIPTION_VALUE;
  }

  return '';
};
