import { Form } from 'components/Forms';
import { Grid, GridItem, GridRow } from 'components/Grid';
import { Text as Paragraph, Title } from 'components/Typography';
import { Container, Content, FormContainer, MaskContainerBottom, MaskContainerTop } from './styles';

export interface INewsletter {
  curveColor: string;
  data: INesletterData;
  trackingEvents?: { trackSubmitTrueEvent: (subscriptionType: string) => void };
  themeName?: string;
}

export interface INesletterData {
  title: string;
  description: string;
  market: string;
  formNewsletter: any;
}

export const Newsletter = ({
  curveColor = '#FDF9F4',
  data,
  trackingEvents,
  themeName,
}: INewsletter) => {
  const formData = data?.formNewsletter?.[0];
  const componentName = 'newletter-form';
  return (
    <div className="newsletter-component text-newsletter" id="success-message">
      <MaskContainerTop data-test={`${componentName}-mask-container-top`} $curveColor={curveColor}>
        <MaskContainerBottom data-test={`${componentName}-mask-container-bottom`}>
          <Grid>
            <GridRow columns={16}>
              <GridItem colSpan={16}>
                <Container>
                  <Content>
                    <Title tag="h2" type="sm">
                      {data?.title}
                    </Title>
                    <Paragraph tag="p" type="md" className="pt-5">
                      {data?.description}
                    </Paragraph>
                  </Content>
                  <FormContainer>
                    <Form
                      withCapture={true}
                      formData={formData}
                      formType="newsletter"
                      trackingEvents={trackingEvents}
                      themeName={themeName}
                    />
                  </FormContainer>
                </Container>
              </GridItem>
            </GridRow>
          </Grid>
        </MaskContainerBottom>
      </MaskContainerTop>
    </div>
  );
};
