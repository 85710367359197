import React from 'react';
import escapeHtml from 'escape-html';
import { LinkElement } from '@graphcms/rich-text-types';
import { LinkRendererProps } from '@graphcms/rich-text-react-renderer';
import { NextLink } from 'components/NextLink';

export function Link({ children, ...rest }: LinkRendererProps) {
  const { href, rel, id, title, openInNewTab, className } = rest;

  const props: Pick<LinkElement, 'rel' | 'id' | 'title' | 'className'> & {
    target?: string;
  } = {};

  if (rel) props.rel = rel;
  if (id) props.id = id;
  if (title) props.title = title;
  if (className) props.className = className;
  if (openInNewTab) props.target = '_blank';

  if (href?.match(/^https?:\/\/|^\/\//i)) {
    return (
      <a
        href={escapeHtml(href)}
        target={openInNewTab ? '_blank' : '_self'}
        rel={rel || 'noopener noreferrer'}
        {...rest}
      >
        {children}
      </a>
    );
  }

  return (
    <NextLink href={escapeHtml(href) ?? ''} {...rest}>
      {children}
    </NextLink>
  );
}
