import { Dictionary, DictionaryService } from 'service/dictionary/dictionaryService';

export type Option = { value: string; label: string };

export const getOptions = (
  values: { value: string; dictionaryKey: string }[],
  translations: Dictionary,
) => {
  return values
    .map(({ value, dictionaryKey }: { value: string; dictionaryKey: string }) => {
      const translation = DictionaryService.getCopy(dictionaryKey, translations.dictionaryItems);

      if (!translation) {
        return null;
      }

      return {
        value,
        label: translation,
      };
    })
    .filter((option) => option !== null) as Option[];
};
