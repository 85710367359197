import { ReactNode } from 'react';
import {
  Container,
  ErrorMessage,
  Label,
  RadioButton,
  RadioInput,
  RadioLabel,
} from '../../../styles';
import type { IRadioButtons } from '../../types';

export const RadioButtons = ({
  field,
  formikProps,
}: {
  field: IRadioButtons;
  formikProps: any;
}): ReactNode => (
  <Container className={field?.hidden ? 'hidden' : ''}>
    <Label>
      {field.title}
      {field.required && ' *'}
    </Label>
    {field.formRadioButtons?.map((radioButton) => (
      <RadioButton key={radioButton.option}>
        <RadioLabel htmlFor={radioButton.option}>
          <RadioInput
            type="radio"
            id={radioButton.option}
            value={radioButton.option}
            name={field.fieldName}
            checked={formikProps.values[field.fieldName] === radioButton.option}
            onChange={() => formikProps.setFieldValue(field.fieldName, radioButton.option)}
            data-test={`radio-${radioButton.option}`}
            className="mr-2"
          />
          <span>{radioButton.label}</span>
        </RadioLabel>
      </RadioButton>
    ))}

    {formikProps.errors[field.fieldName] && (
      <ErrorMessage className="text-red-700" data-test={`radio-${field.fieldName}-error`}>
        {formikProps.errors[field.fieldName]}
      </ErrorMessage>
    )}
  </Container>
);
