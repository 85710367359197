import React from 'react';

export const BlobUpperCorner = ({
  color = '#EFDBB2',
  customSize = false,
}: {
  color?: string;
  customSize?: boolean;
}) => (
  <svg
    fill="none"
    height={customSize ? '100%' : '310'}
    viewBox="0 0 361 310"
    width={customSize ? '100%' : '361'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m314.672 256.593c0 29.496-23.855 53.407-53.281 53.407-13.457 0-25.749-5.001-35.127-13.249-13.255-11.658-33.812-21.936-50.845-17.361-74.551 20.023-150.9033-23.291-170.53891-96.744-7.26889-27.192-5.746249-54.702 2.837-79.584 6.15311-17.8373-.81993-40.2597-3.51917-58.9383-.26302-1.8202-.39918-3.6815-.39917-5.5746 0-21.2901 17.21855-38.54910675 38.45875-38.5491 5.6905.00000057 11.0924 1.23882 15.9518 3.46207 14.0764 6.44012 31.1567 13.94533 46.1027 9.93113 50.853-13.65807 102.545 2.1544 136.501 36.9007 9.126 9.3378 22.076 14.1961 35.108 14.691 6.17.2343 12.418 1.123 18.656 2.7166 46.356 11.8428 74.605 58.1315 63.097 103.3895-4.293 16.883-13.513 31.238-25.793 41.986-11.605 10.159-17.209 28.072-17.209 43.516z"
      fill={color || '#EFDBB2'}
    />
  </svg>
);

export const BlobLowerCorner = () => (
  <svg
    fill="none"
    height="249"
    viewBox="0 0 326 249"
    width="326"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m170.421 47.1279c-24.774 7.3928-37.614-1.6974-51.93-4.9251-30.3951-6.8529-75.7596 2.3128-96.5814 28.4749-36.2679 45.5693-26.49792 113.6863 21.8219 152.1433 48.3199 38.457 116.8915 32.691 153.1595-12.879 11.274-14.165 26.181-34.999 45.252-43.481 19.071-8.483 43.702-18.614 60.545-37.289 23.923-26.527 29.181-53.9873 15.136-83.5071-14.045-29.5197-53.703-56.4149-89.294-40.59055s-33.335 34.66065-58.109 42.05355z"
      fill="#EFDBB2"
    />
  </svg>
);
export const BlobUpperCornerMobile = () => (
  <svg
    fill="none"
    height="143"
    viewBox="0 0 130 143"
    width="130"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m158.088 117.698c0 13.529-11.993 24.497-26.788 24.497-6.766 0-12.946-2.294-17.661-6.078-6.664-5.347-17.0002-10.061-25.5641-7.963-37.4822 9.184-75.8707-10.683-85.74307-44.3757-3.65463-12.4726-2.889082-25.0912 1.42638-36.5045 3.09366-8.1819-.41224-18.4668-1.76936-27.0346-.13224-.8349-.20069-1.6886-.20069-2.557 0-9.76562 8.65714-17.68220309 19.33624-17.6822 2.861.00000026 5.577.568236 8.0202 1.58803 7.0773 2.95403 15.6649 6.3966 23.1795 4.55531 25.5677-6.264834 51.5569.98823 68.6299 16.92616 4.588 4.2831 11.099 6.5116 17.651 6.7386 3.102.1074 6.244.5151 9.38 1.2461 23.307 5.4322 37.51 26.6646 31.724 47.4238-2.159 7.7443-6.794 14.3287-12.968 19.2589-5.835 4.6601-8.653 12.8761-8.653 19.9611z"
      fill="#EFDBB2"
    />
  </svg>
);
export const BlobLowerCornerMobile = () => (
  <svg
    fill="none"
    width="155"
    height="118"
    viewBox="0 0 155 118"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M61.2371 21.3286C66.4569 23.054 72.1489 24.9355 81.148 22.251C86.8121 20.5613 89.5089 17.6157 92.6581 14.1761C96.0676 10.4521 100.007 6.14893 108.817 2.2332C125.765 -5.29929 144.648 7.50299 151.336 21.5546C158.023 35.6062 155.52 48.6777 144.128 61.3046C136.617 69.6312 125.851 74.3893 117.055 78.2768C116.46 78.5396 115.874 78.7984 115.299 79.0541C107.157 82.6747 100.609 91.0221 95.472 97.5696C94.8801 98.3241 94.3069 99.0548 93.7522 99.7515C76.4828 121.443 43.8314 124.188 20.8233 105.882C-2.18478 87.5764 -6.8369 55.1523 10.4325 33.4608C20.3471 21.0075 41.948 16.6446 56.4209 19.9066C58.0374 20.271 59.6144 20.7922 61.2371 21.3286Z"
      fill="#EFDBB2"
    />
  </svg>
);
export const BlobUpperRight = () => (
  <svg
    fill="none"
    height="100%"
    viewBox="0 0 130 143"
    width="100%"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#EFDBB2"
      d="m158.088 117.698c0 13.529-11.993 24.497-26.788 24.497-6.766 0-12.946-2.294-17.661-6.078-6.664-5.347-17.0002-10.061-25.5641-7.963-37.4822 9.184-75.8707-10.683-85.74307-44.3757-3.65463-12.4726-2.889082-25.0912 1.42638-36.5045 3.09366-8.1819-.41224-18.4668-1.76936-27.0346-.13224-.8349-.20069-1.6886-.20069-2.557 0-9.76562 8.65714-17.68220309 19.33624-17.6822 2.861.00000026 5.577.568236 8.0202 1.58803 7.0773 2.95403 15.6649 6.3966 23.1795 4.55531 25.5677-6.264834 51.5569.98823 68.6299 16.92616 4.588 4.2831 11.099 6.5116 17.651 6.7386 3.102.1074 6.244.5151 9.38 1.2461 23.307 5.4322 37.51 26.6646 31.724 47.4238-2.159 7.7443-6.794 14.3287-12.968 19.2589-5.835 4.6601-8.653 12.8761-8.653 19.9611z"
    />
  </svg>
);
