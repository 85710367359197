import { SelectBoxArrowDown } from '@icons/SelectBoxArrowDown';
import { SelectBoxArrowUp } from '@icons/SelectBoxArrowUp';
import NextImage from 'components/NextImage';
import { ReactNode, useState } from 'react';
import {
  Container,
  ErrorMessage,
  FormLabel,
  LeftIcon,
  MainLabel,
  OptionLabel,
  OptionWrapper,
  OptionsBox,
} from '../../../styles';
import type { ISelect, Option } from '../../types';
import { getLabel } from './helper';

export const SelectInput = ({
  field,
  formikProps,
  count,
}: {
  field: ISelect;
  formikProps: any;
  count: number;
}): ReactNode => {
  const defaultOption = field.options?.[0];
  const [selectedOption, setSelectedOption] = useState(defaultOption);
  const [showOptions, setShowOptions] = useState<boolean>(false);

  const selectOption = (event: any, selected: Option) => {
    event.preventDefault();
    setSelectedOption(selected);
    setShowOptions(false);
    formikProps.setFieldValue(field.fieldName, selected.value);
  };

  const showOptionsData = () => setShowOptions(!showOptions);

  return (
    <Container className={field?.hidden ? 'hidden' : ''}>
      <MainLabel>
        <div className="relative w-[70vw] md:w-full">
          <FormLabel tag="label" type="lead" className="text-gray-500">
            {getLabel(field)}
          </FormLabel>
          <LeftIcon>
            {selectedOption?.asset?.[0]?.url && (
              <NextImage
                src={selectedOption?.asset?.[0]?.url}
                className="align-top inline-block
  mr-6"
                alt="Select Input icon"
              />
            )}
          </LeftIcon>
          <input
            type="text"
            value={selectedOption?.label}
            className="rounded-2xl pr-5 pl-4 py-2.5 font-neutral tracking-wider w-full bg-transparent md:text-md md:leading-26 sm:text-sm sm:leading-6"
            onClick={showOptionsData}
            readOnly
          />
          <input name={field?.fieldName} type="hidden" value={selectedOption?.value} readOnly />
          <div className="absolute right-5 bottom-3">
            {showOptions ? <SelectBoxArrowUp /> : <SelectBoxArrowDown />}
          </div>
        </div>
      </MainLabel>
      <OptionsBox className={showOptions ? '' : 'hidden'}>
        {field?.options?.map((option: Option) => (
          <OptionWrapper
            className={`border-b border-grayBorder m-0${
              option.id === selectedOption?.id ? ' selected' : ''
            }`}
            key={option.id}
          >
            <OptionLabel onClick={(e) => selectOption(e, option)}>
              {!!option.asset && (
                <NextImage
                  src={option?.asset[0].url}
                  alt="Select Input Icon"
                  className="align-middle inline-block
  mr-6"
                />
              )}
              <input
                type="radio"
                defaultValue={option?.value}
                className="hidden"
                data-icon-left={option?.asset?.[0].url || ''}
                onChange={() => formikProps.setFieldValue(option.value)}
                checked={formikProps.values[option.value]}
              />
              {option.label}
            </OptionLabel>
          </OptionWrapper>
        ))}
      </OptionsBox>
      {formikProps.errors[field.fieldName] ||
      (field.required && !formikProps.values[field.fieldName] && count > 1) ? (
        <ErrorMessage data-test={`select-${field.fieldName}-error`}>
          {formikProps.errors[field.fieldName]}
        </ErrorMessage>
      ) : (
        ''
      )}
    </Container>
  );
};
