import { Grid, GridRow, GridItem } from 'components/Grid';
import { Text as Paragraph } from 'components/Typography';
import { Wrapper, LongTextContainer } from './styles';
import { RichTextRenderer } from 'components/RichTextRenderer/RichTextRenderer';
import { RichTextContent } from '@graphcms/rich-text-types';

interface ILongText {
  horizontalAlignment?: 'Left' | 'Center' | 'Right';
  longText?: {
    html: string;
    json: Record<string, any>;
  } | null;
}

export const LongText = ({ longText, horizontalAlignment }: ILongText) => (
  <Wrapper>
    <Grid>
      <GridRow columns={16}>
        <GridItem colStart={3} colSpan={12}>
          <LongTextContainer
            data-test="long-text"
            className={`text-${horizontalAlignment?.toLowerCase() || 'left'}`}
          >
            {longText?.html && !longText?.json && (
              <Paragraph tag="div" type="md">
                <div dangerouslySetInnerHTML={{ __html: longText?.html }} />
              </Paragraph>
            )}
            {longText?.json && <RichTextRenderer content={longText.json as RichTextContent} />}
          </LongTextContainer>
        </GridItem>
      </GridRow>
    </Grid>
  </Wrapper>
);
