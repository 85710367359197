import React from 'react';
import { ImageProps } from '@graphcms/rich-text-types';
import NextImage from 'components/NextImage';

export function Image({ src, width, height, altText, mimeType }: Partial<ImageProps>) {
  return (
    <NextImage
      src={src ?? ''}
      alt={altText ?? ''}
      width={width}
      mimeType={mimeType}
      height={height}
      preferredWidth={1000}
    />
  );
}
