export const ChevronLeft = ({ className }: { className: string }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.22689 11.9698C6.92437 12.2817 6.92437 12.7183 7.22689 13.0302L14.3059 20.2661C14.6084 20.578 15.0924 20.578 15.3345 20.2661L15.758 19.8294C16.0605 19.5175 16.0605 19.0809 15.758 18.769L9.58655 12.4688L15.8185 6.23099C16.0605 5.9191 16.0605 5.48246 15.8185 5.17057L15.3345 4.73392C15.0924 4.42203 14.6084 4.42203 14.3059 4.73392L7.22689 11.9698Z"
        fill="#2D2A29"
      />
    </svg>
  );
};
