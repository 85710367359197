import { ReactNode } from 'react';
import { Container, ErrorMessage, Input, Label } from '../../../styles';
import type { IPasswordInputWithConfirmation } from '../../types';
import { getLabel } from './helper';

export const PasswordWithConfirmationInput = ({
  field,
  formikProps,
}: {
  field: IPasswordInputWithConfirmation;
  formikProps: any;
}): ReactNode => (
  <Container>
    <Label>
      {getLabel(field)}
      <Input
        type="password"
        name="password"
        onChange={formikProps.handleChange}
        value={formikProps.values.password}
        data-test={`password-${field.label}`}
        autoComplete="new-password"
        $hasError={!!formikProps.errors.password}
      />
    </Label>
    {formikProps.errors.password && (
      <ErrorMessage data-test={`password-${field.label}-error`}>
        {formikProps.errors.password}
      </ErrorMessage>
    )}

    <Label>
      {field.confirmationTitle}
      <Input
        type="password"
        name="passwordConfirmation"
        onChange={formikProps.handleChange}
        value={formikProps.values.passwordConfirmation}
        data-test={`password-confirmation-${field.label}`}
        autoComplete="new-password"
        $hasError={!!formikProps.errors.passwordConfirmation}
      />
    </Label>
    {formikProps.errors.passwordConfirmation && (
      <ErrorMessage data-test={`password-confirmation-${field.label}-error`}>
        {formikProps.errors.passwordConfirmation}
      </ErrorMessage>
    )}
  </Container>
);
