import { ReactNode } from 'react';
import { Container, ErrorMessage, Input, Label } from '../../../styles';
import type { INumberInput } from '../../types';
import { getLabel } from './helper';

// TODO: Figure out how to use FormikProps type with dynamic field type
export const NumberInput = ({
  field,
  formikProps,
}: {
  field: INumberInput;
  formikProps: any;
}): ReactNode => {
  const error = formikProps.errors[field.fieldName];
  return (
    <Container className={field?.hidden ? 'hidden' : ''}>
      <Label>
        {getLabel(field)}
        <Input
          type="number"
          name={field.fieldName}
          onChange={formikProps.handleChange}
          value={formikProps.values[field.fieldName]}
          data-test={`text-${field.fieldName}`}
          $hasError={!!error}
        />
      </Label>
      {error && <ErrorMessage data-test={`text-${field.fieldName}-error`}>{error}</ErrorMessage>}
    </Container>
  );
};
