import { ReactNode } from 'react';
import { Accordion } from '../../../../Accordion';
import { Title } from '../../../../Typography';
import { AccordionContainer } from '../../../styles';
import { Checkbox } from './Checkbox';
import { CheckboxCollection } from './CheckboxCollection';
import { EmailInput } from './EmailInput';
import { RadioButtons } from './RadioButtons';
import { SelectInput } from './SelectInput';
import { TextInput } from './TextInput';
import { TextareaInput } from './TextareaInput';

export const FormAccordion = ({
  field,
  formikProps,
  count,
}: {
  field: any;
  formikProps: any;
  count: number;
}): ReactNode => {
  const formBody = field?.genericAccordionItems[0]?.form[0]?.formFields?.map((item: any) => {
    switch (item.__typename) {
      case 'FormEmailInput':
        return <EmailInput formikProps={formikProps} field={item} count={count} key={item?.id} />;
      case 'FormTextInput':
        return <TextInput formikProps={formikProps} field={item} count={count} key={item?.id} />;
      case 'FormSelectComponent':
        return <SelectInput formikProps={formikProps} field={item} count={count} key={item?.id} />;
      case 'FormCheckboxCollection':
        return <CheckboxCollection formikProps={formikProps} field={item} key={item?.id} />;
      case 'FormCheckbox':
        return <Checkbox formikProps={formikProps} field={item} count={count} key={item?.id} />;
      case 'FormRadioButtonCollection':
        return <RadioButtons formikProps={formikProps} field={item} key={item?.id} />;
      case 'FormTextareaInput':
        return (
          <TextareaInput formikProps={formikProps} field={item} count={count} key={item?.id} />
        );
      default:
        return false;
    }
  });
  const accordionData = [
    {
      title: (
        <Title tag="div" type="sm">
          {field?.genericAccordionItems?.[0]?.title}
        </Title>
      ),
      body: formBody,
    },
  ];
  return (
    <AccordionContainer className="py-4 sm:w-full md:w-1/2 form-accordion">
      <Accordion
        data-test={`${field?.genericAccordionItems?.[0].id}-generic-accordion`}
        name="form-accordion"
        data={accordionData}
        $isNumbered={field?.numbered}
        collapsed
      />
    </AccordionContainer>
  );
};
