import { ErrorMessage as RHFErrorMessage } from '@hookform/error-message';
import { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { FieldErrors, FieldValues, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { Dictionary, DictionaryService } from 'service/dictionary/dictionaryService';
import { getLabel } from '../../../DynamicForm/components/Inputs/helper';
import { CheckboxInput, ErrorMessage, FormLabel, MainLabel } from '../../../styles';

export type MultiCheckboxProps = {
  fieldName: string;
  checkboxes: {
    value: string;
    label: string;
  }[];
  required?: boolean;
  translations: Dictionary;
  register: UseFormRegister<FieldValues>;
  setFieldValue: UseFormSetValue<FieldValues>;
  errors: FieldErrors<FieldValues>;
};

export const MultiCheckbox = ({
  fieldName,
  required,
  checkboxes,
  register,
  setFieldValue,
  errors,
  translations,
}: MultiCheckboxProps): ReactNode => {
  const [inputValue, setInputValue] = useState<string>('');
  const requiredErrorMessage = DictionaryService.getCopy(
    'requiredCheckboxMessage',
    translations.dictionaryItems,
  );
  const label = DictionaryService.getCopy(`${fieldName}Label`, translations.dictionaryItems);

  useEffect(() => {
    setFieldValue(fieldName, inputValue);
  }, [inputValue, fieldName, setFieldValue]);

  const getUpdatedValue = (value: string, isChecked: boolean) => {
    if (isChecked && !inputValue) {
      return value;
    }

    if (isChecked) {
      return `${inputValue}+${value}`;
    }

    return inputValue
      .split('+')
      .filter((activeCheckbox) => activeCheckbox !== value)
      .join('+');
  };

  const handleCheckboxClick = (value: string, isChecked: boolean) => {
    setInputValue(getUpdatedValue(value, isChecked));
  };

  return (
    <div className="mb-[18px]">
      <MainLabel>
        <FormLabel tag="span" type="lead" className="text-gray-500">
          {getLabel({ label, required })}
        </FormLabel>
        <input
          type="text"
          hidden
          readOnly
          {...register(fieldName, { required: required && requiredErrorMessage })}
        />
      </MainLabel>
      <div className="flex flex-wrap">
        {checkboxes.map(({ label, value }) => {
          return (
            <label key={label} className="mr-4">
              <CheckboxInput
                type="checkbox"
                value={value}
                name={`${fieldName}-${value}`}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleCheckboxClick(value, event.target.checked)
                }
              />
              {label}
            </label>
          );
        })}
      </div>

      <RHFErrorMessage
        errors={errors}
        name={fieldName}
        render={({ message }: { message: string }) => <ErrorMessage>{message}</ErrorMessage>}
      />
    </div>
  );
};
