import NextImage from 'components/NextImage';
import { Step } from '../..';
import { StepValue } from '../../state/reducer';
import { FieldsMapper } from '../StepFields/Mapper';

type StepContentProps = {
  step: Step;
  stepValues: StepValue[];
  onFieldValueChange: (payload: StepValue) => void;
  showErrorMessage?: boolean;
};

export const StepContent = ({
  step,
  stepValues,
  onFieldValueChange,
  showErrorMessage,
}: StepContentProps) => {
  return (
    <div className="flex flex-col md:flex-row md:justify-between">
      <div className="md:w-[calc(50%_-_20px)] mb-[20px] md:mb-0">
        <NextImage
          src={step?.image?.url}
          alt={step?.image?.altText ?? 'question image'}
          width={step?.image?.width ?? 400}
          height={step?.image?.height ?? 320}
          className="rounded-md m-auto object-cover max-h-[200px] md:max-h-[320px] md:m-0"
        />
      </div>
      <div className="md:w-[calc(50%_-_20px)]">
        <FieldsMapper
          field={step?.formField}
          stepValues={stepValues}
          onFieldValueChange={onFieldValueChange}
          showErrorMessage={showErrorMessage}
        />
      </div>
    </div>
  );
};
