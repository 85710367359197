import { CheckIcon } from '@icons/CheckIcon';
import { WarningIcon } from '@icons/WarningIcon';
import { ReactNode } from 'react';
import {
  CheckIconSpan,
  Container,
  ErrorMessage,
  FormLabel,
  Input,
  MainLabel,
  WarningIconSpan,
} from '../../../styles';
import type { ITextInput } from '../../types';
import { getLabel, getTodaysDate } from './helper';

// TODO: Figure out how to use FormikProps type with dynamic field type
export const TextInput = ({
  field,
  formikProps,
  count,
}: {
  field: ITextInput;
  formikProps: any;
  count: number;
}): ReactNode => {
  const error = formikProps.errors[field.fieldName];
  const hasError = error || (field.required && !formikProps.values[field.fieldName] && count > 1);
  const hasValue = !!formikProps.values[field.fieldName];
  return (
    <Container className={field?.hidden ? 'hidden' : ''}>
      <MainLabel>
        <FormLabel tag="span" type="lead" className="text-gray-500">
          {getLabel(field)}
        </FormLabel>
        <Input
          type={field.date ? 'date' : 'text'}
          name={field.fieldName}
          onChange={formikProps.handleChange}
          value={formikProps.values[field.fieldName]}
          placeholder={field.placeholderText}
          data-test={`text-${field.fieldName}`}
          $hasError={!!error}
          max={getTodaysDate()}
        />
        {!hasError && hasValue && (
          <CheckIconSpan $isDate={!!field.date}>
            <CheckIcon />
          </CheckIconSpan>
        )}
        {hasError && (
          <WarningIconSpan $isDate={!!field.date}>
            <WarningIcon />
          </WarningIconSpan>
        )}
      </MainLabel>
      {hasError && (
        <ErrorMessage data-test={`text-${field.fieldName}-error`}>
          {field.requiredErrorMessage}
        </ErrorMessage>
      )}
    </Container>
  );
};
