import { Success } from '@icons/Success';
import {
  SuccessMessage as StyledSuccessMessage,
  SuccessDescription,
  SuccessWrapper,
} from 'components/Forms/styles';
import { Grid, GridItem, GridRow } from 'components/Grid';

type SuccessMessageProps = {
  title: string;
  description: string;
};

export const SuccessMessage = ({ title, description }: SuccessMessageProps) => {
  return (
    <Grid>
      <GridRow>
        <GridItem>
          <SuccessWrapper>
            <Success />
            <StyledSuccessMessage tag="div" type="sm">
              {title}
            </StyledSuccessMessage>
            <SuccessDescription tag="div" type="md">
              {description}
            </SuccessDescription>
          </SuccessWrapper>
        </GridItem>
      </GridRow>
    </Grid>
  );
};
