import tw from 'tailwind-styled-components';
import styled from 'styled-components';

const MaskContainerTopTw = tw.div`
  curve-mask-inverted
  inverted
  w-full
`;
export const MaskContainerTop = styled(MaskContainerTopTw as any)<{ $curveColor?: any }>`
  background-color: ${(props) => props.$curveColor || '#000'};
  padding-bottom: 25px;
  overflow: hidden;
  z-index: 10;
  position: relative;
  @media (min-width: 768px) {
    padding-bottom: 35px;
  }
`;

export const MaskContainerBottom = tw.div`
  curve-mask-inverted
  inverted
  overflow-hidden
`;

export const Container = tw.div`
  flex
  md:py-36
  md:flex-row
  flex-col
  pt-40
  pb-10
  md:pb-20
`;

export const FormContainer = styled.div`
  margin-left: -6%;
  .form-fields {
    width: 100%;
  }
  .form-accordion {
    width: 100%;
  }
  @media (min-width: 768px) {
    width: 60%;
    margin-left: inherit;
  }
`;

export const Content = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    width: 40%;
    padding-right: 48px;
  }
`;
