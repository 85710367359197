import { State, StepValue } from './reducer';

export enum ActionTypes {
  NEXT_STEP = 'NEXT_STEP',
  PREVIOUS_STEP = 'PREVIOUS_STEP',
  CHANGE_STEP_VALUE = 'CHANGE_STEP_VALUE',
  FORM_SUBMIT_SUCCESS = 'FORM_SUBMIT_SUCCESS',
}

export type Action =
  | {
      type: ActionTypes.NEXT_STEP;
      payload?: never;
    }
  | {
      type: ActionTypes.PREVIOUS_STEP;
      payload?: never;
    }
  | {
      type: ActionTypes.CHANGE_STEP_VALUE;
      payload: StepValue;
    }
  | {
      type: ActionTypes.FORM_SUBMIT_SUCCESS;
      payload?: never;
    };

const changeStepValues = (state: State, payload: StepValue) => {
  if (state.stepValues.some((value) => value.title === payload.title)) {
    return {
      ...state,
      stepValues: state.stepValues.map((stepValue) =>
        stepValue.title === payload.title ? payload : stepValue,
      ),
    };
  }

  return {
    ...state,
    stepValues: [...state.stepValues, payload],
  };
};

const formSubmitSuccess = (state: State) => ({
  ...state,
  formSubmitted: true,
});

const nextStep = (state: State) => ({
  ...state,
  currentStepIndex: state.currentStepIndex + 1,
});

const previousStep = (state: State) => ({
  ...state,
  currentStepIndex: state.currentStepIndex - 1,
});

export const Actions = {
  changeStepValues,
  nextStep,
  previousStep,
  formSubmitSuccess,
};
