import { ChevronLeft } from '@icons/ChevronLeft';
import { LiquidButton } from 'components/LiquidButton';
import { DictionaryService, type Dictionary } from 'service/dictionary/dictionaryService';
import { COLORS } from 'styles/constants';
import { ActionTypes } from '../../state/actions';

type ControlsProps = {
  isBackButtonVisible: boolean;
  isLastStep: boolean;
  onControlsClick: (action: ActionTypes.NEXT_STEP | ActionTypes.PREVIOUS_STEP) => void;
  onSubmit: () => void;
  translations: Dictionary;
  submitText: string;
};

export const Controls = ({
  isBackButtonVisible,
  onControlsClick,
  onSubmit,
  isLastStep,
  translations,
  submitText,
}: ControlsProps) => {
  const previousStepTranslation =
    DictionaryService.getCopy('previousStep', translations.dictionaryItems) ?? 'back';
  const nextStepTranslation =
    DictionaryService.getCopy('nextStep', translations.dictionaryItems) ?? 'next';

  return (
    <div className="flex flex-col-reverse items-start min-xs:flex-row min-xs:justify-between min-xs:items-center min-xs:mb-0">
      {isBackButtonVisible && (
        <button
          className="uppercase tracking-xs mb-4 min-xs:mb-0"
          onClick={() => onControlsClick(ActionTypes.PREVIOUS_STEP)}
        >
          <ChevronLeft className="inline-block mr-4" />
          {previousStepTranslation}
        </button>
      )}
      <span className="block min-xs:ml-auto cursor-pointer">
        <LiquidButton
          onButtonClick={() => (isLastStep ? onSubmit() : onControlsClick(ActionTypes.NEXT_STEP))}
          text={isLastStep ? submitText : nextStepTranslation}
          height={50}
          width={200}
          isWidthAuto
          textColor={COLORS.white}
          color={COLORS.primary}
        />
      </span>
    </div>
  );
};
