import { Link } from 'components/Link';
import type { InternalLink } from 'types/link';

export type TLink = {
  openInNewTab: boolean;
  displayName: string;
  url?: string;
  internalLink?: InternalLink;
};

export type LinksProps = {
  links: TLink[];
};

export const Links = ({ links }: LinksProps) => {
  return (
    <ul className="flex justify-center tracking-xs uppercase">
      {links.map((link) => (
        <li className="m-2" key={link.displayName}>
          <Link href={link.url} internalLink={link} openInNewTab={link.openInNewTab}>
            {link.displayName}
          </Link>
        </li>
      ))}
    </ul>
  );
};
