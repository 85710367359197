import { CheckIcon } from '@icons/CheckIcon';
import { WarningIcon } from '@icons/WarningIcon';
import { ReactNode, useState } from 'react';
import {
  CheckIconSpan,
  Container,
  ErrorMessage,
  FormLabel,
  Input,
  MainLabel,
  WarningIconSpan,
} from '../../../styles';
import type { IDateInput } from '../../types';
import { getLabel } from './helper';

export const DateInput = ({
  field,
  formikProps,
  count,
}: {
  field: IDateInput;
  formikProps: any;
  count: number;
}): ReactNode => {
  const [fieldChanged, setFieldChanged] = useState(true);

  const error = formikProps.errors[field.fieldName];
  const hasError = error || (field.required && !formikProps.values[field.fieldName] && count > 1);

  const displayValue = fieldChanged ? formikProps.values[field.fieldName] : '';
  const hasValue = !!formikProps.values[field.fieldName];

  const handleChange = (e: any) => {
    setFieldChanged(true);
    formikProps.handleChange(e);
  };
  return (
    <Container className={field?.hidden ? 'hidden' : ''}>
      <MainLabel>
        <FormLabel tag="span" type="lead" className="text-gray-500">
          {getLabel(field)}
        </FormLabel>
        <Input
          type="date"
          name={field.fieldName}
          onChange={handleChange}
          value={displayValue}
          placeholder={field.placeholderText}
          data-test={`text-${field.fieldName}`}
          $hasError={!!error}
        />
        {!hasError && hasValue && (
          <CheckIconSpan $isDate>
            <CheckIcon />
          </CheckIconSpan>
        )}
        {hasError && (
          <WarningIconSpan $isDate>
            <WarningIcon />
          </WarningIconSpan>
        )}
      </MainLabel>
      {hasError && (
        <ErrorMessage data-test={`text-${field.fieldName}-error`}>* {error}</ErrorMessage>
      )}
    </Container>
  );
};
