import { ReactNode } from 'react';
import { Container, ErrorMessage, Input, Label } from '../../../styles';
import type { IPasswordInput } from '../../types';
import { getLabel } from './helper';

export const PasswordInput = ({
  field,
  formikProps,
}: {
  field: IPasswordInput;
  formikProps: any;
}): ReactNode => {
  const error = formikProps.errors[field.fieldName];
  return (
    <Container className={field?.hidden ? 'hidden' : ''}>
      <Label>
        {getLabel(field)}
        <Input
          type="password"
          name={field.fieldName}
          onChange={formikProps.handleChange}
          value={formikProps.values[field.fieldName]}
          data-test={`password-${field.fieldName}`}
          autoComplete="password"
          $hasError={!!error}
        />
      </Label>
      {error && (
        <ErrorMessage data-test={`password-${field.fieldName}-error`}>{error}</ErrorMessage>
      )}
    </Container>
  );
};
