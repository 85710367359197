import { BlobUpperCorner, BlobUpperCornerMobile } from '@icons/Blobs';
import NextImage from '../../NextImage';
import clsx from 'clsx';

const CLASS_NAMES = {
  topRight: {
    container: 'top-2 md:-right-32 sm:-right-10 md:w-metaBall',
    blobId: 'higher-blob',
    image: 'p-5',
  },
  bottomRight: {
    container: 'bottom-0 md:bottom-10 md:-right-32 sm:-right-10',
    blobId: '',
    image: '',
  },
  bottomLeft: {
    container: 'bottom-10 md:bottom-0 md:-left-32 sm:-left-10 ',
    blobId: 'lower-blob',
    image: 'left-10',
  },
  topLeft: {
    container: 'top-5 md:-left-32 sm:-top-10 sm:-left-10',
    blobId: '',
    image: '',
  },
};

interface IMetaBallImage {
  src?: string;
  type: keyof typeof CLASS_NAMES;
}

export const MetaBallImage = ({ src, type }: IMetaBallImage) => {
  if (!src) {
    return null;
  }

  const styles = CLASS_NAMES[type];
  return (
    <div
      className={clsx(
        'absolute md:w-metaBall md:h-metaBall sm:w-metaBallMobile sm:h-metaBallMobile',
        styles.container,
      )}
    >
      <div className="relative">
        <div
          className="z-10 absolute md:h-metaBall md:w-metaBall sm:w-metaBallMobile sm:h-metaBallMobile"
          id={styles.blobId}
        >
          <div className="hidden md:flex">
            <BlobUpperCorner />
          </div>
          <div className="flex md:hidden">
            <BlobUpperCornerMobile />
          </div>
        </div>
        <div className="z-20 absolute">
          <NextImage
            src={src}
            alt=""
            className={clsx('z-20 relative', styles.image)}
            width={260}
            height={260}
          />
        </div>
      </div>
    </div>
  );
};
