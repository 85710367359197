import { StepValue } from '../../../state/reducer';
import { MultiCheckbox, type MultiCheckboxField } from '../MultiCheckbox';
import { Radios, type RadioField } from '../Radios';

const componentMap = {
  MultiStepPromotionFormRadio: Radios,
  MultiStepPromotionFormCheckboxes: MultiCheckbox,
};

export type AvailableFields = MultiCheckboxField | RadioField;

export type SharedFieldProps = {
  stepValues: StepValue[];
  onFieldValueChange: (payload: StepValue) => void;
  showErrorMessage?: boolean;
};

type MultiStepFormFieldsProps = SharedFieldProps & {
  field: AvailableFields;
};

export const FieldsMapper = ({
  field,
  stepValues,
  onFieldValueChange,
  showErrorMessage,
}: MultiStepFormFieldsProps) => {
  const FieldComponent = componentMap[field.__typename] as any;

  if (FieldComponent) {
    return (
      <FieldComponent
        {...field}
        stepValues={stepValues}
        onFieldValueChange={onFieldValueChange}
        showErrorMessage={showErrorMessage}
      />
    );
  }

  return null;
};
