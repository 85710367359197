import { RichText } from '@graphcms/rich-text-react-renderer';
import { RichTextContent } from '@graphcms/rich-text-types';
import { elements } from './templates';

export const RichTextRenderer = ({ content }: { content: RichTextContent }) => {
  return (
    <RichText
      content={content}
      renderers={{
        ...elements,
      }}
    />
  );
};
