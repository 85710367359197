import type { FormFieldAttributes } from '../../types';
import { FormAccordion } from '../Inputs/Accordion';
import { Checkbox } from '../Inputs/Checkbox';
import { CheckboxCollection } from '../Inputs/CheckboxCollection';
import { DateInput } from '../Inputs/DateInput';
import { EmailInput } from '../Inputs/EmailInput';
import { FileUploader } from '../Inputs/FileUploader';
import { FormList } from '../Inputs/FormList';
import { HiddenInput } from '../Inputs/HiddenInput';
import { NumberInput } from '../Inputs/NumberInput';
import { PasswordInput } from '../Inputs/PasswordInput';
import { PasswordWithConfirmationInput } from '../Inputs/PasswordWithConfirmationInput';
import { RadioButtons } from '../Inputs/RadioButtons';
import { SelectInput } from '../Inputs/SelectInput';
import { SubmitButton } from '../Inputs/SubmitButton';
import { TextInput } from '../Inputs/TextInput';
import { TextareaInput } from '../Inputs/TextareaInput';

// Maps form field types to their respecive components.

export const componentMap = {
  FormEmailInput: EmailInput,
  FormPasswordInput: PasswordInput,
  FormPasswordInputWithConfirmation: PasswordWithConfirmationInput,
  FormSubmitButton: SubmitButton,
  FormTextInput: TextInput,
  FormDateInput: DateInput,
  FormHiddenInput: HiddenInput,
  FormTextareaInput: TextareaInput,
  FormCheckbox: Checkbox,
  FormRadioButtonCollection: RadioButtons,
  FormSelectComponent: SelectInput,
  FormNumberInput: NumberInput,
  FormCheckboxCollection: CheckboxCollection,
  GenericAccordion: FormAccordion,
  FormFileUploader: FileUploader,
  FormList,
};

// Builds Formik initialValues object, according to form's fields
export const initialValueMap = (fields: FormFieldAttributes[]) => {
  const initialValues: any = {};
  fields?.forEach((field: any) => {
    if (field.__typename === 'FormPasswordInputWithConfirmation') {
      initialValues.password = '';
      initialValues.passwordConfirmation = '';
    } else if (field.__typename === 'GenericAccordion') {
      field.genericAccordionItems?.[0]?.form?.[0]?.formFields.map((fieldItem: any) => {
        initialValues[fieldItem?.fieldName] = fieldItem?.initialValue || '';
      });
    } else if (field.__typename === 'FormCheckbox') {
      initialValues[field?.fieldName] = false;
    } else if (field.__typename === 'FormList') {
      initialValues[field?.fieldName] = [];
      field.formCheckboxes.map((checkbox: any) => {
        if (checkbox.checked) {
          initialValues[field?.fieldName].push(checkbox.fieldName);
        }
      });
    } else if (field.fieldName) {
      if (field.initialValue) {
        initialValues[field?.fieldName] = field?.initialValue;
      } else {
        initialValues[field?.fieldName] = '';
      }
    }
  });
  return initialValues;
};
