import { WidgetInstance } from 'friendly-challenge';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';

interface FriendlyCaptchaProps {
  onSuccess: (solution: string) => void;
}

const SUPPORTED_LANGUAGES = ['de', 'en']; // extend later for other languages
const DEFAULT_LANGUAGE = 'en';

export const FriendlyCaptcha = ({ onSuccess }: FriendlyCaptchaProps) => {
  const container = useRef();
  const widget = useRef();
  const { locale } = useRouter();

  const currentAppLAng = locale?.substring(0, 2) || DEFAULT_LANGUAGE;
  const widgetLang = SUPPORTED_LANGUAGES.includes(currentAppLAng)
    ? currentAppLAng
    : DEFAULT_LANGUAGE;
  const doneCallback = (solution: string) => {
    onSuccess(solution);
  };

  useEffect(() => {
    if (!widget.current && container.current) {
      // @ts-ignore
      widget.current = new WidgetInstance(container.current, {
        doneCallback: doneCallback,
        sitekey: 'FCMPA11HE47NSBFI',
      });
    }

    return () => {
      (widget.current as any)?.reset();
    };
  }, [container]);
  // @ts-ignore
  return <div ref={container} data-lang={widgetLang} className="frc-captcha" />;
};
