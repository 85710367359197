import { Action, ActionTypes, Actions } from './actions';

export type StepValue = {
  title: string;
  value: string;
  isValid: boolean;
};

export type State = {
  currentStepIndex: number;
  stepValues: StepValue[];
  formSubmitted: boolean;
};

export const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionTypes.NEXT_STEP:
      return Actions.nextStep(state);
    case ActionTypes.PREVIOUS_STEP:
      return Actions.previousStep(state);
    case ActionTypes.CHANGE_STEP_VALUE:
      return Actions.changeStepValues(state, action.payload);
    case ActionTypes.FORM_SUBMIT_SUCCESS:
      return Actions.formSubmitSuccess(state);
    default:
      return state;
  }
};
