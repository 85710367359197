import { useEffect, useState, RefObject } from 'react';

type ObserverOptions = {
  freeze?: boolean;
  threshold?: number;
};

export const useInView = (
  ref: RefObject<HTMLElement>,
  options: ObserverOptions = { freeze: false, threshold: 0 },
) => {
  const [inView, setInView] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setInView(entry.isIntersecting);
      },
      { threshold: options.threshold },
    );

    if (options.freeze && inView) {
      observer.disconnect();
      return;
    }

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref, options]);

  return inView;
};
