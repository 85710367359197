import { ErrorMessage as RHFErrorMessage } from '@hookform/error-message';
import type { FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form';
import { DictionaryService, type Dictionary } from 'service/dictionary/dictionaryService';
import { getLabel } from '../../../DynamicForm/components/Inputs/helper';
import {
  CheckboxInput,
  CheckboxLabel,
  CheckboxLabelHtml,
  ErrorMessage,
  FieldContainer,
  StyledLabel,
} from '../../../styles';

export type SingleCheckboxProps = {
  fieldName: string;
  html?: string;
  required?: boolean;
  translations: Dictionary;
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
};

export const SingleCheckbox = ({
  fieldName,
  register,
  errors,
  required,
  translations,
  html,
}: SingleCheckboxProps) => {
  const requiredErrorMessage = DictionaryService.getCopy(
    'requiredCheckboxMessage',
    translations.dictionaryItems,
  );
  const label = DictionaryService.getCopy(`${fieldName}Label`, translations.dictionaryItems);
  return (
    <FieldContainer>
      <StyledLabel htmlFor={fieldName}>
        <CheckboxInput
          type="checkbox"
          id={fieldName}
          {...register(fieldName, { required: required && requiredErrorMessage })}
        />
        <CheckboxLabel>
          {label ? getLabel({ label, required }) : null}
          {html && (
            <CheckboxLabelHtml
              dangerouslySetInnerHTML={{
                __html: `<div>${html}</div> ${!label && required ? '<span>*</span>' : ''}`,
              }}
            />
          )}
        </CheckboxLabel>
      </StyledLabel>
      <RHFErrorMessage
        errors={errors}
        name={fieldName}
        render={({ message }: { message: string }) => <ErrorMessage>{message}</ErrorMessage>}
      />
    </FieldContainer>
  );
};
