import { useGlobalContext } from 'components/Context/hooks/useGlobalContext';
import { NextLink } from 'components/NextLink';
import { ReactNode } from 'react';
import { LocalizationService } from 'service/localization/localizationService';
import styled from 'styled-components';
import tw from 'tailwind-styled-components';
import type { ISharedTemplateProps } from 'templates/TemplateMapper/types';
import type { InternalLink } from 'types/link';

const CustomLinkStyle = styled.span`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 0%;
    left: 50%;
    bottom: -2px;
    border-bottom: 1px solid black;
    transition: all 0.5s;
  }

  &:hover {
    &:after {
      width: 98%;
      left: 1%;
    }
  }
`;

export const StyledLink = tw(CustomLinkStyle)`
  md:inline-block
  cursor-pointer
`;

export interface ILink extends ISharedTemplateProps {
  children?: ReactNode;
  href?: string;
  target?: string;
  openInNewTab?: boolean;
  displayName?: string;
  internalLink?: InternalLink;
}

export const Link = ({
  children,
  href: hrefProp,
  target: targetProp,
  openInNewTab,
  displayName,
  internalLink,
}: ILink & ISharedTemplateProps): ReactNode => {
  const { pathTranslations } = useGlobalContext();
  const target = targetProp ?? openInNewTab ? '_blank' : '_self';
  const href =
    hrefProp ??
    LocalizationService.getLocalizedLinkPath({
      item: internalLink,
      pathTranslations,
    });

  return (
    <StyledLink>
      {target === '_blank' ? (
        <NextLink href={href ? href : '/'} target={target} rel={'noopener noreferrer'}>
          {displayName ?? children}
        </NextLink>
      ) : (
        <NextLink href={href ? href : '/'} target={target}>
          {displayName ?? children}
        </NextLink>
      )}
    </StyledLink>
  );
};
