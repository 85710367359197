import { ErrorMessage as RHFErrorMessage } from '@hookform/error-message';
import { ReactNode } from 'react';
import { FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form';
import { Dictionary, DictionaryService } from 'service/dictionary/dictionaryService';
import { getLabel } from '../../../DynamicForm/components/Inputs/helper';
import { ErrorMessage, FieldContainer, FormLabel, Input, MainLabel } from '../../../styles';

export type TextInputProps = {
  fieldName: string;
  required?: boolean;
  translations: Dictionary;
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
};

export const TextInput = ({
  fieldName,
  required,
  register,
  errors,
  translations,
}: TextInputProps): ReactNode => {
  const requiredErrorMessage = DictionaryService.getCopy(
    'requiredMessage',
    translations.dictionaryItems,
  );
  const label = DictionaryService.getCopy(`${fieldName}Label`, translations.dictionaryItems);

  return (
    <FieldContainer>
      <MainLabel>
        <FormLabel tag="span" type="lead" className="text-gray-500">
          {getLabel({ label, required })}
        </FormLabel>
        <Input
          {...register(fieldName, { required: required && requiredErrorMessage })}
          type="text"
          placeholder={label}
        />
      </MainLabel>
      <RHFErrorMessage
        errors={errors}
        name={fieldName}
        render={({ message }: { message: string }) => <ErrorMessage>{message}</ErrorMessage>}
      />
    </FieldContainer>
  );
};
