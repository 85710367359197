export const getBrand = (brand: string) => {
  if (brand === 'DrOetker') {
    return 'Dr. Oetker';
  }

  if (brand === 'Bakkennl') {
    return 'Bakken.nl';
  }

  return brand;
};
