import { ReactNode } from 'react';
import { Input } from '../../../styles';
import type { IHiddenInput } from '../../types';

export const HiddenInput = ({
  field,
  formikProps,
}: {
  field: IHiddenInput;
  formikProps: any;
}): ReactNode => (
  <Input
    type="hidden"
    name={field.fieldName}
    value={formikProps.values[field.fieldName]}
    data-test={`text-${field.fieldName}`}
  />
);
