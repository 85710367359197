import { Grid, GridItem, GridRow } from 'components/Grid';
import { Text, Title } from 'components/Typography';
import { DictionaryService, type Dictionary } from 'service/dictionary/dictionaryService';
import { Step } from '../..';

type StepInfoProps = {
  step: Pick<Step, 'title' | 'description'>;
  currentStep: number;
  stepCount: number;
  translations: Dictionary;
};

export const StepInfo = ({ step, currentStep, stepCount, translations }: StepInfoProps) => {
  const stepTranslation = DictionaryService.getCopy('step', translations.dictionaryItems) ?? 'Step';
  return (
    <Grid skipPaddingOnMobile>
      <GridRow columns={16} className="mb-[40px]">
        <GridItem colSpan={16}>
          <Text tag="p" type="lg" className="text-center mb-2">
            {stepTranslation} {currentStep}/{stepCount}
          </Text>
          {step.title && (
            <Title tag="h2" type="sm" className="text-center mb-1 font-medium">
              {step.title}
            </Title>
          )}
          {step.description && (
            <Text tag="p" type="md" className="text-center">
              {step.description}
            </Text>
          )}
        </GridItem>
      </GridRow>
    </Grid>
  );
};
