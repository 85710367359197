import { ReactNode } from 'react';
import { Container, ErrorMessage, Label, Textarea } from '../../../styles';
import type { ITextareaInput } from '../../types';
import { getLabel } from './helper';

export const TextareaInput = ({
  field,
  formikProps,
  count,
}: {
  field: ITextareaInput;
  formikProps: any;
  count: number;
}): ReactNode => {
  const error = formikProps.errors[field.fieldName];
  return (
    <Container className={field?.hidden ? 'hidden' : ''}>
      <Label>
        {getLabel(field)}
        <Textarea
          value={formikProps.values[field.fieldName]}
          name={field.fieldName}
          onChange={formikProps.handleChange}
          placeholder={field.placeholderText}
          data-test={`textarea-${field.fieldName}`}
          $hasError={!!error}
        />
      </Label>
      {error || (field.required && !formikProps.values[field.fieldName] && count > 1) ? (
        <ErrorMessage data-test={`textarea-${field.fieldName}-error`}>{error}</ErrorMessage>
      ) : (
        ''
      )}
    </Container>
  );
};
